import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'

function Timeline() {
  return (
    <div id="history">
      <div className="items-container">
        <h1>Career History</h1>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
            contentArrowStyle={{ borderRight: '7px solid  white' }}
            date="07-2024 – 09-2024"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Full Stack Engineer Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Menzah 5, Tunisia, TenStep EPM</h4>
            <p>
            Development of a Web Application for Leave Management for the HR Department <br/>
            
            <strong>Used Technologies :</strong> React JS, Spring Boot, MongoDB, GitHub, Docker, Kubernetes, Jenkins
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="07-2023 – 09-2023"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Staff Engineer Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Beja, Tunisia, STEG </h4>
            <p>
            Exploration of Web Technologies and Computer Systems,<br/> 
            <strong>Used Technologies :</strong> Native PHP, MySQL
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="02-2022 – 06-2022"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Embedded Systems Engineer Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Bizerte, Tunisia , IBL ELECTRONICS</h4>
            <p>
              Full-stack Development, API Development, User Experience <br/>
              Implementation of a Connected IoT Solution, titled "Real-time Monitoring and Display of the Synthetic Yield Rate of a Fully Automated CMS Line." <br/>
              <strong>Used Technologies :</strong> Raspberry Pi Microcontroller, Pi Camera, Firebase Cloud, Flutter, Dart ,Python , FTP, HTTP.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="06-2021 – 09-2021"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Full Stack developer Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Monastir,
            Tunisia , RedLean Services</h4>
            <p>
            A Platform Designed to Manage Interns, Track Their Progress, Schedules, and Assignments, as well as Handle Administrative Tasks Related to Internships <br/>
            <strong>Used Technologies :</strong> Angular, Bootstrap, Spring Boot, MySQL, GitHub
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Timeline;