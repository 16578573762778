import React from "react";
import mock001 from '../assets/images/Django.png';
import mock002 from '../assets/images/2.png';
import mock003 from '../assets/images/Flutter.png';
import mock004 from '../assets/images/Docker.png';
import mock005 from '../assets/images/SpringAngular.png';
import mock006 from '../assets/images/Ecommerce.png';
import mock007 from '../assets/images/Laravel.png';
import mock008 from '../assets/images/react.png';


import '../assets/styles/Project.scss';

function Project() {
    return(
    <div className="projects-container" id="projects">
        <h1>Personal Projects</h1>
        <div className="projects-grid">
            <div className="project">
                <a href="https://github.com/khalloufiHamzaUFO/Plant-disease-predictor" target="_blank" rel="noreferrer"><img src={mock001} className="zoom" alt="thumbnail" width="100%" /></a>
                <a href="https://github.com/khalloufiHamzaUFO/Plant-disease-predictor" target="_blank" rel="noreferrer"><h2>Plant disease detection</h2></a>
                <p>This web app uses deep learning and computer vision to detect and classify crop diseases, leveraging image-based ML algorithms with Kaggle datasets, Django, and Flask.</p>
                </div>
            <div className="project">
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><img src={mock002} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><h2>Energy guard</h2></a>
                <p>Energy Guard is a web application designed to monitor and display real-time energy consumption metrics, empowering users to manage and optimize energy use efficiently. I developed this application from scratch using modern web technologies, integrating IoT devices with data visualization to provide actionable insights.</p>
            </div>
            <div className="project">
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><img src={mock003} className="zoom" alt="thumbnail" width="100%" /></a>
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><h2>YieldSense: Real-Time CMS Performance Monitor</h2></a>
                <p>is a Flutter-based IoT application developed to monitor and display the real-time synthetic yield rate of a fully automated CMS production line. Designed from scratch, this solution utilizes Raspberry Pi and Pi Camera to capture live data, which is processed and stored in Firebase Cloud. Through real-time data visualization, the app provides users with valuable insights to optimize production efficiency and ensure consistent operational performance.</p>
            </div>
            <div className="project">
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><img src={mock004} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><h2>Automated CI/CD Pipeline </h2></a>
                <p>This project demonstrates an automated CI/CD pipeline using Jenkins and Docker for efficient software delivery. Vagrant manages the virtual environment, streamlining code testing, building, and deployment.</p>
            </div>
            <div className="project">
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><img src={mock005} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><h2>University Management System</h2></a>
                <p>A web application for administrators to oversee dormitories and for students to manage reservations, built with Angular, Spring Boot, Spring Security, and MySQL.</p>
            </div>
            <div className="project">
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><img src={mock006} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><h2>Distributed E-commerce Platform</h2></a>
                <p>An e-commerce application built with a microservices architecture, leveraging Docker for containerization and multiple technologies for a robust, scalable system. This distributed setup integrates Spring Boot, Django, and Angular with service discovery via Eureka and databases including MySQL, H2, and MongoDB.</p>
            </div>
            <div className="project">
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><img src={mock007} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><h2>Food Rescue E-commerce Platform</h2></a>
                <p>An e-commerce application focused on reducing food waste by selling unsold food from restaurants at discounted prices. Built with Laravel, this platform applies semantic web principles to enhance searchability and provide meaningful connections between data, creating a seamless experience for users looking to save money and support sustainability.</p>
            </div>
            <div className="project">
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><img src={mock008} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/khalloufiHamzaUFO/" target="_blank" rel="noreferrer"><h2>HR Leave Management Web Application</h2></a>
                <p>A web app for the HR department to manage employee leave, built with React JS, Spring Boot, and MongoDB. Containerized with Docker, orchestrated with Kubernetes, and integrated with Jenkins for CI/CD.</p>
            </div>

        </div>
    </div>
    );
}

export default Project;