import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker, faPython } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';

const labelsFirst = [
    "React",
    "Angular",
    "TypeScript",
    "JavaScript",
    "HTML5",
    "CSS3",
    "SASS",
    "SpringBoot",
    "Express.js",
    "Django",
    "Python",
    "SQL",
    "MongoDB",
    "Postman",
    "Microservice",
    "MVC",
    "MVVC",
    "agile scrum",


];

const labelsSecond = [
    "Git",
    "GitHub Actions",
    "Docker",
    "Gitlab",
    "Vagrant",
    "Linux",
    "Jenkins",
    "SonarQube",
    "Prometheus",
    "NgRok",

];

const labelsThird = [
    "OpenAI",
    "Jupyter",
    "Raspberry Pi",
    "Firebase",
    "ESP32",
    "Arduino",
    "Streamlit",
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faReact} size="3x"/>
                    <h3>Full Stack Web Development</h3>
                    <p>I have built a diverse array of web applications from scratch using modern technologies such as React , Angular, and the MERN stack. With expertise in frameworks like Spring Boot and Spring. I have a strong proficiency in the SDLC process and frontend + backend development.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsFirst.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faDocker} size="3x"/>
                    <h3>DevOps & Automation</h3>
                    <p>Once the application is built, I help clients set up DevOps testing, CI/CD pipelines, and deployment automation to support the successful Go-Live.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsSecond.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faPython} size="3x"/>
                    <h3>Intelligent Solutions with AI & IoT</h3>
                    <p>Stay relevant in the market by leveraging the latest AI models and IoT technology in your projects. I have professional experience building enterprise-grade AI-powered and IoT-enabled solutions to empower intelligent decision-making.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsThird.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;